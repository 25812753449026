<template>
  <div>
    <div>
      <div
        style="background-color: #f0e68c; text-align: center; font-size: 18px"
      >
        В разработке
      </div>
      <div style="width: 95%; padding: 20px; display: flex">
        <!-- создание -->
        <div
          style="
            width: 300px;
            padding: 20px;
            margin: 5px;
            border-radius: 5px;
            -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
          "
        >
          Создание нового нарушения:
          <div>
            <b>Дата:</b>
            <input
              type="date"
              id="Date"
              class="form-control"
              required
              v-model="newViolations.date"
            />
          </div>
          <div>
            <b>Ответственный за информацию</b>
            <input
              type="text"
              id="responsible"
              class="form-control"
              required
              v-model="newViolations.responsible"
            />
          </div>
          <div>
            <b>Класс</b>
            <select
              class="form-select"
              id="class"
              v-model="newViolations.classID"
              @change="getStudentsList()"
            >
              <option
                v-for="(user, index) in classList"
                :key="index"
                v-bind:value="user"
              >
                {{ user.className }}
              </option>
            </select>
          </div>

          <div style="width: 200px">
            <b>Ученик</b>
            <select class="form-select" v-model="newViolations.studentID">
              <!-- @change="changeStudent()" -->
              <option
                v-for="user in studentsList"
                :key="user._id"
                v-bind:value="user"
              >
                {{ user.FirstName }} {{ user.LastName }}
              </option>
            </select>
          </div>
          <div>
            <b>Группа нарушений:</b>
            <select
              class="form-select"
              id="class"
              v-model="newViolations.group"
            >
              <option>Нарушения дисциплины</option>
              <option>Нарушения учебной деятельности</option>
              <option>Нарушение внешнего вида</option>
              <option>Курение\Алкоголь\Вейп</option>
              <option>Порча школьного имущества</option>
              <option>Прочие нарушения</option>
            </select>
          </div>

          <div style="top: 10px">
            <b>Описание нарушения:</b>
            <textarea
              name=""
              v-model="newViolations.description"
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
            ></textarea>
          </div>
          <v-card-actions id="createNew">
            <button @click="createViolations()" class="btn btn-success">
              Создать
            </button>
          </v-card-actions>
        </div>
        <!-- карточка нарушения -->
        <div style="width: 400px; margin: 5px">
          <div
            style="
              padding: 20px;
              display: flex;
              flex-direction: column;
              border-radius: 5px;
              -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
              -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
              box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            "
            :class="{
              'class-status-BAD': newViolations.status == 'Не обработано',
              'class-status-accept': newViolations.status == 'Решено',
              'class-mark-item-process':
                newViolations.status == 'Проведена беседа',
            }"
          >
            <div style="font-size: 16px"><b>Карточка нарушения №___</b></div>
            <div>
              <b>Дата:</b>&nbsp;{{
                new Date(newViolations.date).toLocaleDateString()
              }}
            </div>
            <div>
              <b>Ответственный за информацию:</b>&nbsp;{{
                newViolations.responsible
              }}
            </div>
            <div>
              <b>Класс:</b>&nbsp;<span v-if="newViolations.classID">{{
                newViolations.classID.className
              }}</span>
            </div>
            <div>
              <b>Ученик:</b>&nbsp;<span v-if="newViolations.studentID"
                >{{ newViolations.studentID.FirstName }}&nbsp;{{
                  newViolations.studentID.LastName
                }}</span
              >
            </div>
            <div><b>Группа:</b>&nbsp;{{ newViolations.group }}</div>
            <div><b>Описание:</b>&nbsp;{{ newViolations.description }}</div>
            <div>
              <b>Статус:</b>&nbsp; {{ newViolations.status }}
              <hr />
              <div>
                <b>Изменение статуса:</b>

                <select
                  class="form-select"
                  id="class"
                  v-model="newViolations.status"
                >
                  <option>Не обработано</option>
                  <option>Проведена беседа</option>
                  <option>Решено</option>
                </select>
              </div>

              <div style="top: 10px">
                <b>Комментарий:</b>
                <textarea
                  name=""
                  v-model="newViolations.comment"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                ></textarea>
              </div>

              <v-card-actions id="updateViol">
                <button
                  @click="createViolations()"
                  class="btn btn-success"
                  style="width: 100%"
                >
                  Обновить
                </button>
              </v-card-actions>
            </div>
          </div>
        </div>
        <div style="width: 400px; margin: 5px">
          <div
            style="
              padding: 20px;
              display: flex;
              flex-direction: column;
              border-radius: 5px;
              -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
              -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
              box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
            "
          >
            Реестр нарушений
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialDataService from "../services/TutorialDataService";

export default {
  data() {
    return {
      classList: [],
      studentsList: [],
      violationsList: [],
      newViolations: {
        studentID: "",
        classID: "",
        responsible: "",
        description: "",
        group: "",
        date: new Date().toISOString().slice(0, 10),
        status: "Не обработано",
        comment: "Пример",
      }, //может быть на весь класс
    };
  },
  methods: {
    createViolations() {
      console.log(this.newViolations);
      alert("Нарушение пока нельзя создать!");
    },
    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
      };
    },
    async getAllClass() {
      await TutorialDataService.getAllCLass()
        .then((response) => {
          this.classList = response.data.map(this.getDisplayClass);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getStudentsList() {
      this.studentsList = [];
      this.newViolations.studentID = "";
      await TutorialDataService.findStudentByClassID(
        this.newViolations.classID
      ).then((response) => {
        let students = Object.values(response.data);
        students.map((student) => {
          if (student != undefined) {
            this.studentsList.push(student);
          }
        });
      });
    },
  },
  mounted() {
    this.getAllClass();
  },
};
</script>

<style scoped>
.class-status-BAD {
  background-color: rgb(255, 170, 187);
}
.class-status-accept {
  background-color: rgb(196, 255, 108);
}
.class-mark-item-process {
  background-color: lightblue;
}
</style>
